import {
  NotificationMode,
  NotificationType,
  OrderAction,
  OrderEvent,
  UpdateCustomerRequest,
} from '@oolio-group/domain';
import { useNotification } from '@oolio-group/hooks';
import {
  useTranslation,
  useLocalization,
  getFullFormattedPhoneNumber,
} from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFela } from 'react-fela';
import { useForm } from 'react-hook-form';
import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { ScreenName } from '../../common/enum';
import theme from '../../common/theme';
import { Background } from '../../component/Background/Background';
import Icon from '../../component/Icon/Icon';
import FormInput from '../../component/Shared/FormInput/FormInput';
import FormPhoneInput from '../../component/Shared/FormPhoneInput/FormPhoneInput';
import { useCart } from '../../hooks/cart/CartProvider';
import { useCustomers } from '../../hooks/orders/useCustomers';
import useBehaviorSubjectState from '../../hooks/rxjs/useSubjectState';
import { useCartNavigationHelper } from '../../hooks/useCartNavigationHelper/useCartNavigationHelper';
import { useSyncOrderEvents } from '../../hooks/useSyncOrderEvents/useSyncOrderEvents';
import { sessionSubject } from '../../state/sessionObservable';
import { omit } from 'lodash';

export type InputScreenParam = StackScreenProps<
  {
    [ScreenName.INPUT_INFO]: {
      type?: string;
    };
  },
  ScreenName.INPUT_INFO
>;

interface FormInput {
  name: string;
  email: string;
  phone: string;
}

const CustomerInputScreen: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { country } = useLocalization();
  const { navigateIdleScreen } = useCartNavigationHelper();
  const { theme } = useFela();
  const { showNotification } = useNotification();
  const { order } = useCart();
  const {
    createCustomer,
    updateCustomer,
    getCustomerById,
    customerMaps,
    loading,
  } = useCustomers();
  const { value: session } = useBehaviorSubjectState(sessionSubject);
  const [selectedCountry, setSelectedCountry] = useState(country);
  const { translate } = useTranslation();
  const { params: routeParams } = useRoute<InputScreenParam['route']>();
  const { type = 'email' } = routeParams ?? {};
  const { control, formState, handleSubmit, reset } = useForm<FormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSendSuccess = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
    navigateIdleScreen();
  };

  const assignedCustomer = useMemo(() => {
    return customerMaps[order?.customer?.id || ''];
  }, [customerMaps, order?.customer?.id]);

  useEffect((): void => {
    if (order?.customer?.id) {
      getCustomerById(order.customer.id);
    }
  }, [order.customer, getCustomerById]);

  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess);

  useEffect(() => {
    if (assignedCustomer) {
      const name = `${assignedCustomer.firstName} ${assignedCustomer.lastName}`;
      reset({
        name,
        email: assignedCustomer.email || '',
        phone: assignedCustomer.phoneNumber || '',
      });
      if (assignedCustomer?.preferredAddress?.isoCountryCode) {
        setSelectedCountry(assignedCustomer?.preferredAddress?.isoCountryCode);
      }
    }
  }, [assignedCustomer, reset]);

  const onSendReceipt = useCallback(
    async (state: FormInput) => {
      if (session) {
        const { name, email, phone } = state;
        let event;
        switch (type) {
          case 'email':
            event = {
              action: OrderAction.CUSTOMER_NOTIFICATION,
              orderId: order.id,
              id: uuidv4(),
              notificationType: NotificationType.SEND_RECEIPT,
              notificationMode: NotificationMode.EMAIL,
              organizationId: session.currentOrganization?.id as string,
              venueId: session.currentVenue?.id as string,
              storeId: session.currentStore?.id as string,
              deviceId: session.device?.id as string,
              timestamp: Date.now(),
              triggeredBy: '',
              email: email.toLowerCase(),
            };
            break;
          case 'sms':
            event = {
              action: OrderAction.CUSTOMER_NOTIFICATION,
              orderId: order.id,
              id: uuidv4(),
              notificationType: NotificationType.SEND_MESSAGE,
              notificationMode: NotificationMode.PHONE,
              organizationId: session.currentOrganization?.id as string,
              venueId: session.currentVenue?.id as string,
              storeId: session.currentStore?.id as string,
              deviceId: session.device?.id as string,
              timestamp: Date.now(),
              triggeredBy: '',
              phone: getFullFormattedPhoneNumber(selectedCountry, phone),
            };
            break;
          default:
            break;
        }
        syncOrderEvents([event] as unknown as OrderEvent[]);

        const input = {
          firstName: name,
          phoneNumber: phone,
          phone: phone
            ? getFullFormattedPhoneNumber(selectedCountry, phone)
            : '',
          email,
          preferredAddress: {
            isoCountryCode: selectedCountry,
          },
        };
        if (order?.customer?.id) {
          const existingPreferredAddress = omit(
            assignedCustomer?.preferredAddress || {},
            '__typename',
          );
          const updateInput = {
            ...input,
            id: order?.customer?.id,
            preferredAddress: {
              ...existingPreferredAddress,
              isoCountryCode: selectedCountry,
            },
          } as UpdateCustomerRequest;
          updateCustomer(updateInput);
        } else {
          createCustomer(input);
        }
      }
    },
    [
      session,
      type,
      syncOrderEvents,
      selectedCountry,
      order?.customer?.id,
      order.id,
      assignedCustomer?.preferredAddress,
      updateCustomer,
      createCustomer,
    ],
  );

  return (
    <Background session={session}>
      <View style={styles.screen}>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={styles.btnBack}
          testID="back-btn"
        >
          <Icon
            style={styles.btnIcon}
            name="UilArrowLeft"
            color={theme.colors.black}
          />
        </TouchableOpacity>
        <KeyboardAvoidingView behavior="padding" style={styles.inputView}>
          <View style={styles.inputContainer}>
            <FormInput
              name="name"
              placeholder={translate('cdsInputInformation.customerName')}
              control={control}
            />
            {type === 'email' && (
              <FormInput
                name="email"
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: translate('cdsInputInformation.invalidEmailInput'),
                  },
                }}
                placeholder={translate('cdsInputInformation.emailPlaceholder')}
                control={control}
                containerStyle={styles.emailInput}
              />
            )}
            {type === 'sms' && (
              <FormPhoneInput
                name="phone"
                placeholder={translate('cdsInputInformation.phonePlaceholder')}
                control={control}
                containerStyle={styles.phoneInput}
                selectedCountry={selectedCountry}
                onChangeCountry={setSelectedCountry}
              />
            )}
            <TouchableOpacity
              testID="btnSendReceipt"
              style={styles.btnSendReceipt}
              onPress={handleSubmit(onSendReceipt)}
              disabled={!formState.isValid || loading}
            >
              {loading ? (
                <View style={styles.loading}>
                  <ActivityIndicator size={28} color={theme.colors.white} />
                </View>
              ) : (
                <Text style={styles.sendReceiptText}>
                  {translate('cdsInputInformation.sendReceipt')}
                </Text>
              )}
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </View>
    </Background>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    width: '100%',
    padding: theme.padding.large,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnBack: {
    width: 38,
    height: 38,
    position: 'absolute',
    top: 20,
    left: 20,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.white,
  },
  btnIcon: {
    alignSelf: 'center',
  },
  inputView: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    width: 560,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.padding.xl,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
  },
  input: {
    fontSize: 38,
    lineHeight: 44,
    fontWeight: '700',
    textAlign: 'center',
  },
  userNameInput: {
    width: '100%',
    height: 60,
  },
  infoInput: {
    marginTop: 20,
    width: '100%',
    height: 60,
  },
  btnSendReceipt: {
    marginTop: 20,
    height: 60,
    width: '100%',
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: theme.colors.primary,
  },
  sendReceiptText: {
    fontWeight: '600',
    color: theme.colors.white,
    fontSize: theme.fontSize.small,
    textTransform: 'uppercase',
  },
  emailInput: {
    marginTop: 20,
  },
  phoneInput: {
    marginTop: 20,
  },
  loading: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.small,
  },
});

export default CustomerInputScreen;
