import { StyleFn } from '@oolio-group/domain';
import scale from '../../../common/theme';
import React from 'react';
import { useFela } from 'react-fela';
import { View, TextInput, Text, ViewStyle, StyleSheet } from 'react-native';
import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

const errorText: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.smallest,
  paddingLeft: theme.padding.small,
  color: theme.colors.danger,
  textAlign: 'left',
});

export interface FormInputProps<T extends FieldValues> {
  control: Control<T>;
  readOnly?: boolean;
  name: FieldPath<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: any;
  placeholder?: string;
  containerStyle?: ViewStyle | ViewStyle[];
}
const styles = StyleSheet.create({
  formInputContainer: {
    display: 'flex',
    width: '100%',
  },
  input: {
    display: 'flex',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: scale.colors.grey3,
    height: 44,
  },
  inputContainer: {
    height: 44,
    borderRadius: 6,
    paddingRight: 12,
    paddingLeft: 12,
    display: 'flex',
    flex: 1,
  },
});

const FormInput = <T extends FieldValues>({
  control,
  readOnly = false,
  rules,
  name,
  placeholder,
  containerStyle,
}: FormInputProps<T>) => {
  const { css, theme } = useFela();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        formState: { isDirty, errors },
      }) => (
        <View style={[styles.formInputContainer, containerStyle]}>
          <View style={styles.input}>
            <TextInput
              onBlur={onBlur}
              onChangeText={onChange}
              value={value as string}
              placeholderTextColor={theme.colors.paragraph}
              placeholder={placeholder}
              editable={!readOnly}
              style={styles.inputContainer}
            />
          </View>
          {isDirty && (errors[name] as FieldError)?.message && (
            <Text style={css(errorText)} testID="errorMessage">
              {(errors[name] as FieldError)?.message}
            </Text>
          )}
        </View>
      )}
    />
  );
};

export default FormInput;
