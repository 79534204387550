import {
  CommonActions,
  NavigationContainer,
  NavigationContainerRef,
  useNavigation,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Tracer } from '@oolio-group/tracer-client';
import React, { useCallback, useEffect, useRef } from 'react';
import { NotificationList } from '../component/Notification/NotificationList';
import { GlobalInterval } from '../hooks/interval/GlobalInterval';
import {
  SubNavigatorName,
  useInitialScreen,
} from '../hooks/login/useInitialScreen';
import IdleScreen from '../screens/IdleScreen/IdleScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import { CartNavigator } from './CartNavigator';
import AssignPos from '../screens/DeviceSelection/DeviceSelection';
import { sessionSubject } from '../state/sessionObservable';
import { skip } from 'rxjs';

const Stack = createStackNavigator();

export const MainNavigator: React.FC = () => {
  const { screen } = useInitialScreen();
  const navigation = useNavigation();

  useEffect(() => {
    sessionSubject.pipe(skip(1)).subscribe(session => {
      if (!session?.authorized) {
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: SubNavigatorName.Login }],
          }),
        );
      }
    });
  }, [navigation]);

  return screen === SubNavigatorName.Idle ? (
    <IdleScreen />
  ) : (
    <Stack.Navigator
      initialRouteName={screen}
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen component={LoginScreen} name={SubNavigatorName.Login} />
      <Stack.Screen component={AssignPos} name={SubNavigatorName.AssignPos} />
      <Stack.Screen component={CartNavigator} name={SubNavigatorName.Cart} />
    </Stack.Navigator>
  );
};

const AppNavigator = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const onNavigationStateChange = useCallback(() => {
    const route = navigationRef.current?.getCurrentRoute();
    Tracer.getInstance().onNavigationStateChange({
      name: route?.name,
      params: route?.params,
    });
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={onNavigationStateChange}
    >
      <GlobalInterval />
      <NotificationList />
      <MainNavigator />
    </NavigationContainer>
  );
};

export default AppNavigator;
