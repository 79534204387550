import { OrderItem, OrderItemModifier } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { computeOrderItemTotal } from '@oolio-group/order-helper';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import theme from '../../common/theme';

export interface ItemProps {
  item: OrderItem;
}

const CartItem: React.FC<ItemProps> = ({ item }) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();

  const modifiers = (item.modifiers ?? [])
    .map(modifier => {
      if (item.quantity > 1) {
        return new Array(modifier.quantity)
          .fill(`${modifier?.name}(x${item.quantity})`)
          .join(', ');
      }
      return `${modifier?.name}${
        modifier.quantity > 1
          ? `(x${(modifier.quantity ?? 1) * (item.quantity ?? 1)})`
          : ''
      }`;
    })
    .join(', ');

  const orderItemModifiersPrice = (modifiers: Array<OrderItemModifier>) => {
    return modifiers.reduce((sum, modifier) => {
      return sum + modifier.unitPrice * (modifier?.quantity * item.quantity);
    }, 0);
  };

  const renderModifiers = () => {
    return (
      modifiers !== '' && (
        <View style={styles.rowStyle}>
          <Text style={styles.smallText} testID="order-item-modifiers-name">
            {modifiers}
          </Text>
          <Text style={styles.smallText} testID="order-item-modifiers-price">
            {formatCurrency(orderItemModifiersPrice(item.modifiers))}
          </Text>
        </View>
      )
    );
  };

  const renderAdjustments = (
    adjustmentAmount: number,
    label: string,
    testID: string,
  ) => {
    return (
      typeof adjustmentAmount === 'number' &&
      adjustmentAmount > 0 && (
        <View style={styles.rowStyle}>
          <Text style={styles.smallText}>{label}</Text>
          <Text style={styles.smallText} testID={testID}>
            {formatCurrency(adjustmentAmount)}
          </Text>
        </View>
      )
    );
  };

  return (
    <View style={styles.itemContainer}>
      <View style={styles.rowStyle}>
        <Text style={styles.largeText} testID="order-item-name-quantity">
          {`${!!item.variant ? item.variant.name : item.product.name}${
            item.quantity > 1 ? ` (x${item.quantity})` : ''
          }`}
        </Text>
        <Text style={styles.largeText} testID="order-item-price">
          {formatCurrency(computeOrderItemTotal(item))}
        </Text>
      </View>
      {!!item.variant && (
        <View style={styles.rowStyle}>
          <Text style={styles.smallText} testID="order-item-variant">
            {item.product.name.replace(`${item.variant.name}-`, '')}
          </Text>
        </View>
      )}
      {renderModifiers()}
      {renderAdjustments(
        item.discountAmount,
        translate('order.discount'),
        'order-item-discount',
      )}
      {renderAdjustments(
        item.surchargeAmount || 0,
        translate('order.surcharge'),
        'order-item-surcharge',
      )}
      {!!item.notes && (
        <Text style={styles.smallText} testID="order-item-notes">
          {translate('cdsCartScreen.itemNote', { note: item.notes.trim() })}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    paddingVertical: theme.spacing.medium,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    paddingRight: theme.spacing.medium,
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallText: {
    fontSize: theme.fontSize.small,
    color: theme.colors.grey6,
    lineHeight: 22,
  },
  largeText: {
    fontWeight: '600',
    fontSize: theme.fontSize.large,
    color: theme.colors.black,
    lineHeight: 40,
  },
});

export default CartItem;
