import 'react-native-get-random-values';
import { ApolloProvider } from '@apollo/client';
import themes from '@oolio-group/branding';
import { getApolloCache } from '@oolio-group/client-utils';
import { GQLClient } from '@oolio-group/gql-client';
import { NotificationProvider } from '@oolio-group/hooks';
import { getTracerLink } from '@oolio-group/tracer-client';
import {
  LocalizationProvider,
  supportedLocales,
} from '@oolio-group/localization';
import React, { useCallback, useState } from 'react';
import { ThemeProvider } from 'react-fela';
import { authFlowLink } from './graphql/authFlowLink';
import config from './config';
import AppNavigator from './navigator/AppNavigator';
import * as settings from './state/preferences';
import { subscriptionStateUtil } from './state/subscriptionStateUtils';
import useBehaviorSubjectState from './hooks/rxjs/useSubjectState';
import { sessionSubject } from './state/sessionObservable';
import { refreshTokenAccessToken } from './utils/refreshTokenAccessToken';
import AnalyticsInitilizer from './analytics/AnalyticsInitilizer';

const DEFAULT_COUNTRY_CODE = 'AU';
const DEFAULT_THEME = 'light';
const DEFAULT_LOCALE = 'en-US';

const App = () => {
  const retryAwait = useCallback(async () => {
    try {
      const session = await settings.getSession();
      if (!session?.authorized) return;
      if ((session.expiredDate as number) > Date.now()) return;
      await refreshTokenAccessToken();
    } catch (error) {
      settings.setSession({ authorized: false });
      sessionSubject.next({ authorized: false });
    }
  }, []);

  // On component mount, restore cache
  // Meanwhile app renders a loading screen
  const [client] = useState(() => {
    const cache = getApolloCache();

    const getSession = async () => {
      return await settings.getSession();
    };

    return new GQLClient(
      {
        cache,
        middlewares: authFlowLink.concat(
          getTracerLink({ uri: config.API_URL }),
        ),
        service: config.API_URL,
        getSession,
        client: 'cds-app',
        version: '1.0',
        retryWait: retryAwait,
      },
      true,
      {
        onChangeSubscriptionState: subscriptionStateUtil.updateState,
      },
    ).getApolloClient();
  });

  //fetch session
  const { value: session } = useBehaviorSubjectState(sessionSubject);

  return (
    <ThemeProvider theme={themes['tillpos'][DEFAULT_THEME]}>
      <ApolloProvider client={client}>
        <AnalyticsInitilizer>
          <LocalizationProvider
            locale={supportedLocales[DEFAULT_LOCALE]}
            country={
              session.currentOrganization?.country || DEFAULT_COUNTRY_CODE
            }
          >
            <NotificationProvider>
              <AppNavigator />
            </NotificationProvider>
          </LocalizationProvider>
        </AnalyticsInitilizer>
      </ApolloProvider>
    </ThemeProvider>
  );
};
export default App;
